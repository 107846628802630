import sensors from 'sa-sdk-javascript'
import pageleave from 'sa-sdk-javascript/dist/web/plugin/pageleave/index.es6.js';
// 神策
sensors.init({
    server_url: `https://mdgj-sjdr.cast.org.cn/sa?project=${location.host.indexOf('4.3') == -1 ? 'production' : 'default'}`,
    is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
    use_client_time: true,
    show_log: true,
    send_type: 'beacon',
    heatmap: {
        //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
        clickmap: 'default',
        //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
        scroll_notice_map: 'not_collect'
    }
});
sensors.use(pageleave,{});
// 初始化 SDK
// 注册公共属性
sensors.registerPage({
    plat_form: document.title,
    plat_type: 'web'
});



sensors.quick('autoTrack', {
    scene_name: $("input[name='sensors_scene_name']").val(),
    store_name: document.title,
    resource_type: $("input[name='resource_type']").val(),
    resource_name: $("input[name='resource_name']").val(),
    resource_id: $("input[name='resource_id']").val()
}); //用于采集 $pageview 事件。
sensors.track('StoreView', {
    forward_page_name: '建家开店',
    forward_page_url: document.referrer,
    page_name: document.title,
    page_url: location.href,
    store_name: $("input[name='test']"),
    organization_name: $("input[name='sensors_org_name']").val(),
    organization_type: $("input[name='sensors_org_type_name']").val(),
    organization_id: $("input[name='sensors_org_id']").val()
});
var start_time = Math.floor((new Date()).getTime() / 1000)
// console.log(Math.floor((new Date()).getTime()))
function pageLeave () {
    sensors.track('WebPageLeave', {
        url_path: location.pathname,
        referrer_host: document.referrer ? new URL(document.referrer).hostname : '',
        referrer: document.referrer,
        event_duration: Math.floor((new Date()).getTime() / 1000) - start_time,
        viewport_position: $(document).scrollTop(),
        title: document.title,
        url: location.href
    });
    start_time = Math.floor((new Date()).getTime() / 1000)
}
$(document).on('blur focus', 'input,textarea', pageLeave)
$(window).resize(function () {
    // pageLeave()
})
$(window).bind('beforeunload', function () {
    pageLeave()
});
// pageLeave()
var widgetBody = []
var resource_formation = []
var parent_label = ''
var product_class = []
var classify_data = []

$('.save-btn').click(function () {
    var resource_api = []
    widgetBody = JSON.parse(sessionStorage.getItem('widgetBody'));
    $.each(widgetBody.body, function (i, v) {
        if (!$.isArray(v.body)) {

            resource_api.push(v.body.api)



        }

    })
    $.ajax({
        url: '/apires/classify',
        type: 'get',
        success: function (res) {
            // 返回301 登录已过期
            classify_data = res.data
            search_type(resource_api, classify_data, '', 1)
            resource_formation = [...new Set(resource_formation)]
            sensors.track('PublishStore', {
                // forward_page_name: '建家开店',
                // forward_page_url: document.referrer,
                page_name: document.title,
                page_url: location.href,
                store_name: $("input[name='test']"),
                organization_name: $("input[name='sensors_org_name']").val(),
                organization_type: $("input[name='sensors_org_type_name']").val(),
                organization_id: $("input[name='sensors_org_id']").val(),
                resource_formation: resource_formation,
            });
        }

    })

})

function search_type (api, _data, parent, num) {
    $.each(_data, function (x, y) {
        $.each(api, function (a, b) {

            if (y.children.length > 0) {
                if (y.value == b) {
                    if (parent) {
                        resource_formation.push(parent)
                        product_class.push(parent)

                    } else {
                        resource_formation.push(y.label)
                        product_class.push(y.label)
                    }

                } else {
                    if (num == 1) {
                        parent_label = y.label
                    }
                    parent_label = parent_label ? parent_label : y.label
                    search_type(api, y.children, parent_label, 2)

                }
            } else {
                if (y.value == b) {
                    if (parent) {
                        resource_formation.push(parent)
                        product_class.push(parent)


                    } else {
                        resource_formation.push(y.label)
                        product_class.push(y.label)


                    }

                }
            }

        })

    })

}
$('.sa_title').click(function () {
    var posCode = $(this).parents('.build-widget-row-box').data('code')
    var api = []
    var product_name = $(this).find('._sa_title') ? $(this).find('._sa_title').text() : $(this).text()
    widgetBody = JSON.parse(sessionStorage.getItem('widgetBody'));

    $.each(widgetBody.body, function (i, v) {
        if (v.pos_code == posCode) {
            api.push(v.body.api)
        }

    })

    $.ajax({
        url: '/apires/classify',
        type: 'get',
        success: function (res) {
            // 返回301 登录已过期
            classify_data = res.data
            search_type(api, classify_data, '', 1)

            console.log(product_name, product_class)
            sensors.track('ProductClick', {
                // forward_page_name: '建家开店',
                // forward_page_url: document.referrer,
                page_name: document.title,
                page_url: location.href,
                store_name: $("input[name='test']"),
                organization_name: $("input[name='sensors_org_name']").val(),
                organization_type: $("input[name='sensors_org_type_name']").val(),
                organization_id: $("input[name='sensors_org_id']").val(),
                in_scene: '',
                product_name: product_name,
                product_class: product_class.join()
            });
        }

    })
})
